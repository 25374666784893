import React, {useEffect, useState} from "react";
import {Typography, Box, Grid, Hidden} from '@material-ui/core';
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../signUpActions";
import {serverSelector} from "../../../shared/errors/errorSelectors";
import {loadingSelector, whiteLabelSelector} from "../../../shared/sharedSelectors";
import {ApplicationState} from "../../../ApplicationState";
import Icon from "../../../shared/components/Icon";
import GoodIcon from '../../../svg/signup-1.svg';
import OkIcon from '../../../svg/signup-2.svg';
import BadIcon from '../../../svg/signup-3.svg';
import Ellipses from '../../../svg/title-ellipses.svg';
import {
    UserProfileModel,
} from "../../../shared/SharedModels";
import Loading from "../../../shared/components/Loading";
import {LoadingStateMap} from "../../../shared/loading/LoadingStateModel";
import * as sharedSelectors from "../../../shared/sharedSelectors";
import * as sharedActions from "../../../shared/sharedActions";
import SignUpForm from "./SignUpForm";

type Props = {
    userProfile: UserProfileModel | undefined;
    confirmEmailVerification: typeof actions.confirmEmailVerification;
    loading: LoadingStateMap;
    verifyReCaptcha: typeof sharedActions.verifyReCaptcha;
    subscriptionTrialDays: number;
    getSubscriptionTrialDays?: typeof sharedActions.getSubscriptionTrialDays;
};


// parameter c means client
const SignUpPage: React.FC<Props & RouteComponentProps<{ userid: string, signupStatus: string, c: string }>> = (props) => {
    const [ redirect, setRedirect ] = useState("");

    const { userid } = props.match.params;

    useEffect(() => {
        if (props.getSubscriptionTrialDays) props.getSubscriptionTrialDays();
    }, [])

    useEffect(() => {
        if (props.userProfile) {
            const {organization} = props.userProfile;
            setRedirect(`/org${organization.id}/setup/customize`);
        }
    }, [props.userProfile]);

    return (
        <Grid container className="entry-container-wrapper">
            {redirect && <Redirect to={redirect}/>}
            <Grid item xs={12} md={6}>
                <Box className="banner-section">
                <Box className="info-container">
                    <Box className="trial-info">
                        {!window.location.href.includes("/signup/appsumo") && !window.location.href.includes("/UserInvite/") &&
                            <>
                                <Typography variant="h2">Try {props.subscriptionTrialDays > 0 ? `${props.subscriptionTrialDays}-day` : null} free trial</Typography>,
                                <Typography>No Credit Card Required</Typography>
                            </>
                        }
                    </Box>
                    <Box className="title-info">
                        <Typography variant="h6">Measure Customer Satisfaction in Realtime</Typography>
                        <Typography variant="h6">Reduce Churn rate and improve retention</Typography>
                        <Typography variant="h6">Identify unhappy customers pro-actively</Typography>
                    </Box>
                    <Hidden only={['xs', 'md']}>
                        <Box className="trial-info">
                            <Typography>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply</Typography>
                        </Box>
                    </Hidden>
                    <Box className="ellipses">
                        <Icon src={Ellipses} />
                    </Box>
                    <Box className="icons">
                        <Box><Icon src={GoodIcon} /></Box>
                        <Box><Icon src={OkIcon} /></Box>
                        <Box><Icon src={BadIcon} /></Box>
                    </Box>
                </Box>
            </Box>
            </Grid>
            <Grid item xs={12} md={6}>
            {props.loading.default && props.loading.default.isLoading ?
                <Box className='entry-container'>
                    <Loading />
                    <Typography>Saving your details, you will get an email to verify your account.</Typography>
                </Box>:
                <SignUpForm userid={userid} />
            }
            </Grid>
            <Hidden only={['md', 'lg', 'xl']}>
                <Grid item xs={12}>
                    <Box className="trial-info-footer">
                        <Typography>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply</Typography>
                    </Box>
                </Grid>
            </Hidden>
        </Grid>
    )

}

const mapStateToProps = (state: ApplicationState) => {
    return {
        userProfile: sharedSelectors.profileSelector(state),
        loading: loadingSelector(state),
        serverErrors: serverSelector(state),
        recaptchaResponse: state.globals.reCaptchaResponse,
        subscriptionTrialDays: state.globals.subscriptionTrialDays,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        signup: actions.signUp,
        signupInvitedUser: actions.signupInvitedUser,
        getInvitedUserProfile: sharedActions.getInvitedUserProfile,
        confirmEmailVerification: actions.confirmEmailVerification,
        verifyReCaptcha: sharedActions.verifyReCaptcha,
        getSubscriptionTrialDays: sharedActions.getSubscriptionTrialDays,
    }, dispatch);
};

const SignUp = connect(mapStateToProps, mapDispatchToProps)(SignUpPage);

export default withRouter(SignUp);
